// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

const stage = import.meta.env.VITE_STAGE as string;
const googleGA = import.meta.env.VITE_GOOGLE_GA as string;
const googleRecaptcha = import.meta.env.VITE_GOOGLE_RECAPTCHA as string;
const apiUrl = import.meta.env.VITE_API_URL as string;

export const isProduction = stage === "production";
export const isDev = stage === "development";
export const isStaging = stage === "staging";

export const isEnvProduction = isProduction || isStaging;

export const config = { stage, isProduction, googleGA, googleRecaptcha, apiUrl };
