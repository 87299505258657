// Copyright 2021 - 2024 Transflox LLC. All rights reserved.

import { Close, Menu } from "@styled-icons/ionicons-solid";
import type React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useScrollLock } from "usehooks-ts";

import { isDev } from "../../config";
import { useKeyWithClickEvents } from "../../hooks/useKeyWithClickEvents";
import cn from "../../services/cn";
import Button2 from "../Common/Button2";
import Container from "../Common/Container";

const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { lock, unlock } = useScrollLock({ autoLock: false });

  return (
    <>
      <header className={cn("h-[80px] text-white fixed w-full z-100", { hidden: openMenu })}>
        <Container className="flex flex-col justify-center h-full">
          <div className="flex items-center gap-2 justify-between">
            <div className="w-[135px] flex items-center gap-3">
              <div
                className="xl:hidden cursor-pointer"
                {...useKeyWithClickEvents(() => {
                  lock();
                  setOpenMenu(true);
                }, "Enter")}
              >
                {openMenu ? <Close size={32} /> : <Menu size={32} />}
              </div>
              <Link to="/" className="text-[32px] select-none">
                p10node
              </Link>
            </div>

            <ul className="h-[100%] items-center select-none gap-[30px] overpass-body text-[17px] hidden xl:flex">
              <li>
                <Link to="/stake" className="h-[36px]">
                  Stake with Us
                </Link>
              </li>

              <li>
                <Link to="/services" className="h-[36px]">
                  Chain Services
                </Link>
              </li>

              <li>
                <Link to="/development" className="h-[36px]">
                  Development
                </Link>
              </li>

              <li>
                <Link to="/posts" className="h-[36px]">
                  Blog & News
                </Link>
              </li>

              {isDev && (
                <li>
                  <Link to="/code-mode" className="h-[36px]">
                    CodeMode
                  </Link>
                </li>
              )}

              <li>
                <a href="http://explorer.p10node.com" className="h-[36px]">
                  CosScan
                </a>
              </li>

              {isDev && (
                <li>
                  <a href="https://nodehealth.p10node.com" rel="noopener noreferrer">
                    NodeHealth
                  </a>
                </li>
              )}
            </ul>

            <div className="w-[135px] flex flex-col items-end">
              <Button2 toExternal="https://docs.p10node.com" noBlank>
                docs
              </Button2>
            </div>
          </div>
        </Container>
      </header>
      <nav className={cn("z-[500] w-full min-h-screen text-white fixed bg-[#101010]", { hidden: !openMenu })}>
        <Container>
          <div className="flex justify-between h-[80px] items-center">
            <div className="flex gap-3 items-center">
              <div
                {...useKeyWithClickEvents(() => {
                  unlock();
                  setOpenMenu(false);
                }, "Enter")}
              >
                <Close size={32} />
              </div>
              <Link to="/" className="text-[32px] select-none">
                p10node
              </Link>
            </div>
          </div>
          <div>
            <ul
              className="mt-12 h-[100%] select-none flex flex-col gap-[30px] overpass-body text-[23px] text-white"
              {...useKeyWithClickEvents(() => {
                unlock();
                setOpenMenu(false);
              }, "Enter")}
            >
              <li>
                <Link to="/stake" className="h-[36px]">
                  Stake with Us
                </Link>
              </li>

              <li>
                <a href="https://nodehealth.p10node.com" rel="noopener noreferrer">
                  NodeHealth
                </a>
              </li>

              <li>
                <Link to="/services" className="h-[36px]">
                  Chain Services
                </Link>
              </li>

              <li>
                <Link to="/development" className="h-[36px]">
                  Development
                </Link>
              </li>

              <li>
                <a href="http://explorer.p10node.com" className="h-[36px]">
                  CosScan
                </a>
              </li>

              <li>
                <Link to="/posts" className="h-[36px]">
                  Blog & News
                </Link>
              </li>

              <li>
                <Link to="/contact" className="h-[36px]">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </nav>
    </>
  );
};

export default Header;
